import dayjs from 'dayjs';

export const getAgeAtStartDate = (
  startDate?: string,
  dateOfBirth?: string
): number => {
  if (!dateOfBirth) return 0;

  return dayjs(startDate).diff(dateOfBirth, 'year');
};
